import { ContainerProps, Room } from "../../screens/BookingScreen"
import { RoomItem } from "../list/RoomItem"
import React, { ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import { View } from "../general/View"
import { Mark } from "@mui/base/useSlider"
import { Slider } from "@mui/material"
import { useLocation } from "react-router-dom"
import { animateScroll as scroll } from "react-scroll"

export interface ReservationContainerProps extends ContainerProps<Map<Room, number>> {
  data?: Room[]
}

export function ReservationContainer({ value, onChange, completed, data }: ReservationContainerProps) {
  const location = useLocation()
  const [rooms, setRooms] = useState<Map<Room, number>>(value)
  const [selectedRoom, setSelectedRoom] = useState<Room>()
  const [open, setOpen] = useState<boolean>(false)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    setRooms(value)
  }, [value])

  useEffect(() => {
    console.log("value", rooms)
    if (sumMapCount() > 0) {
      completed()
    }
  }, [rooms])

  useEffect(() => {
    if (location.state) {
      //scrollToElement(location.state as string)
    }
  }, [location.state])

  useEffect(() => {
    if (selectedRoom) {
      onChange(new Map<Room, number>(value.set(selectedRoom, 1)))
    }
  }, [selectedRoom])

  const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId)
    if (element) {
      scroll.scrollTo(element.offsetTop, {
        duration: 800,
        smooth: "easeInOutQuart",
      })
    }
  }


  const pluralRule = (number: number, key?: string) => {
    const pluralRules = new Intl.PluralRules(i18n.language)
    const pluralKey = pluralRules.select(number)
    return `people_count${key ?? ""}_${pluralKey}`
  }

  const marks: Mark[] = selectedRoom
    ? Array.from({ length: selectedRoom.capacity + 1 }, (_, index) => ({
      value: index,
      label: t(pluralRule(index), { value: index }),
    }))
    : []
  const sumMapCount = () => {
    let sum = 0
    rooms.forEach((value) => {
      sum += value
    })
    return sum
  }

  return (<>{data?.map((room: Room) =>
    <RoomItem
      key={room.roomNumber}
      value={room}
      buttonText={
        (value.get(room) ?? 0) > 0
          ? t("selected", {
            value: t(
              pluralRule((value.get(room) as number), "_for"),
              { value: (value.get(room) as number) },
            ),
          })
          : t("select")
      }
      onPress={() => {
        setSelectedRoom(room)
        setOpen(true)
      }}
    />)}
    <ReservationDialog value={selectedRoom} visible={open} onSave={() => setOpen(false)} onClose={() => {
      if (selectedRoom) {
        onChange(new Map<Room, number>(value.set(selectedRoom, 0)))
      }
      setOpen(false)
    }}>
      <h4>{t("choose_people_count")}</h4>
      <View style={{ width: "50%" }}>
        <Slider
          defaultValue={rooms.get(selectedRoom as Room) ?? 1}
          marks={marks}
          max={selectedRoom?.capacity ?? 0}
          min={0}
          step={1}
          style={{ width: "50vw" }}
          valueLabelDisplay="auto"
          onChange={(_, val) => {
            onChange(new Map<Room, number>(value.set(selectedRoom as Room, val as number)))
          }}
        /></View>
    </ReservationDialog>
  </>)
}

export function ReservationDialog(props: {
  onClose: () => void,
  onSave: () => void,
  visible: boolean,
  children?: ReactNode,
  value?: Room
}) {
  const { t } = useTranslation()

  return (<Dialog
    aria-labelledby="customized-dialog-title"
    open={props.visible}
    onClose={props.onClose}
  >
    <DialogTitle id="customized-dialog-title" sx={{ m: 0, p: 2 }}>
      {`#${props.value?.roomNumber} ${props.value?.roomName}`}
    </DialogTitle>
    <IconButton
      aria-label="close"
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
      onClick={props.onClose}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent dividers>
      <View
        style={{
          height: "20vh",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          flexDirection: "column",
        }}>{props.children}</View>
    </DialogContent>
    <DialogActions style={{ justifyContent: "space-between" }}>
      <Button onClick={props.onClose}>
        {t("cancel")}
      </Button>
      <Button onClick={props.onSave}>
        {t("confirm")}
      </Button>
    </DialogActions>
  </Dialog>)
}
