import React, { useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { HomeScreen } from "./screens/HomeScreen"
import { BookingScreen } from "./screens/BookingScreen"
import { Header } from "./components/Header"
import { useTranslation } from "react-i18next"

export default function App() {
  const {i18n} = useTranslation()

  useEffect(() => {
    const language = localStorage.getItem("language")
    if (language) {
      i18n.changeLanguage(language)
    }
  }, [])

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route element={<HomeScreen />} path="/" />
        <Route element={<BookingScreen />} path="/booking">
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
