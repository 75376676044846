import moment from "moment"

export function formattedDate(date: Date) {
  return (moment(date)).format("DD-MMM-YYYY")
}

export function durationInDays(firstDate?: Date, secondDate?: Date) {
  if (!firstDate || !secondDate) return 0
  return Math.ceil(Math.abs(secondDate.getTime() - firstDate.getTime()) / (1000 * 3600 * 24)) - 1
}
