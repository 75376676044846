import { RequestedRoom, Room } from "../../screens/BookingScreen"
import React, { CSSProperties } from "react"
import { useTranslation } from "react-i18next"
import { durationInDays } from "../../utils/date"

export function RoomTable({ bookedRooms, durationInDays }: { bookedRooms: RequestedRoom[], durationInDays: number }) {
  const { t, i18n } = useTranslation()

  console.debug("RoomTable rooms:", bookedRooms)
  if (!bookedRooms || bookedRooms.length === 0) return <p>{t("room.no_selected.message")}</p>

  const totalTablePrice = Array.from(bookedRooms.entries()).reduce(
    (total, [count, room ]) => total + room.bookedPrice * durationInDays,
    0,
  )
  const pluralRule = (number: number) => {
    const pluralRules = new Intl.PluralRules(i18n.language)
    const pluralKey = pluralRules.select(number)
    return `people_count_${pluralKey}`
  }

  const pluralRuleAll = (key: "rooms" | "people" | "days", number: number) => {
    const pluralRules = new Intl.PluralRules(i18n.language)
    const pluralKey = pluralRules.select(number)
    return `${key}_count_${pluralKey}`
  }


  return (
    <div style={styles.table}>
      <div style={styles.columnHeader}>{t("room.label")}</div>
      <div style={styles.columnHeader}>{t("guests")}</div>
      <div style={styles.columnHeader}>{t("price")}</div>
      {bookedRooms.map(room => (
        <React.Fragment key={room.roomName}>
          <div
            style={styles.columnContent}>{t("room.numbered_room", {
            number: room.roomNumber,
            name: room.roomName,
          })}</div>
          <div style={styles.columnContent}>{t(pluralRule(room.bookedCapacity), { value: room.bookedCapacity })}</div>
          <div
            style={styles.columnContentRight}>
            {t(pluralRuleAll("days", durationInDays), { value: durationInDays }) +
              " * [" + room.bookedPrice + " " + room.priceUnit + "] = " +
              t("room_total", {
                value: room.bookedPrice * durationInDays,
                priceUnit: room.priceUnit,
              })}
          </div>
        </React.Fragment>
      ))}
      <div style={{ ...styles.columnContent, ...styles.totalRow, textAlign: "center" }}>{t("total")}</div>
      <div style={{ ...styles.columnContent, ...styles.totalRow }}>&nbsp;</div>
      <div
        style={{ ...styles.columnContentRight, ...styles.totalRow }}>{t("room_total", {
        value: totalTablePrice,
        priceUnit: "CZK bez DPH",
      })}</div>
      <div style={{ ...styles.columnContent, ...styles.totalRow, textAlign: "center" }}>{t("total")} bez DPH</div>
      <div style={{ ...styles.columnContent, ...styles.totalRow }}>&nbsp;</div>
      <div
        style={{ ...styles.columnContentRight, ...styles.totalRow }}>{t("room_total", {
        value: totalTablePrice * 1.12,
        priceUnit: "CZK s DPH",
      })}</div>
    </div>
  )
}

const styles = {
  table: {
    width: "100%",
    marginTop: 20,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    border: "1px solid #e0e0e0",
    borderRadius: 3,
    overflow: "hidden",
  } as CSSProperties,
  columnHeader: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    display: "inline-block",
    width: "33.33%",
    backgroundColor: "#fff",
    padding: 10,
    textAlign: "center",
    borderBottom: "1px solid #e0e0e0",
    fontWeight: "bold",
  } as CSSProperties,
  columnContent: {
    display: "inline-block",
    width: "33.33%",
    padding: 10,
    textAlign: "center",
    borderBottom: "1px solid #e0e0e0",
  } as CSSProperties,
  columnContentRight: {
    display: "inline-block",
    width: "33.33%",
    padding: 10,
    textAlign: "right",
    borderBottom: "1px solid #e0e0e0",
  } as CSSProperties,
  totalRow: {
    backgroundColor: "#fff",
    fontWeight: "bold",
    display: "inline-block",
    width: "33.33%",
    padding: 10,
    textAlign: "right",
  } as CSSProperties,
}
