import { useEffect, useMemo, useState } from "react"
import { addDoc, collection } from "firebase/firestore"
import { firestore } from "../services/firebase/firebaseConfig"
import jsonData from "../assets/data/rooms.json"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button, Step, StepButton, Stepper } from "@mui/material"
import { ContactForm, ContactFormContainer } from "../components/booking/ContactFormContainer"
import { ReservationContainer } from "../components/booking/ReservationContainer"
import { CheckInOut, CheckInOutContainer } from "../components/booking/CheckInOutContainer"
import { ConfirmationContainer } from "../components/booking/ConfirmationContainer"
import { Screen } from "../components/general/Screen"
import { View } from "src/components/general/View"
import { durationInDays } from "../utils/date"
import { useWindowDimensions } from "../hooks/WindowDimensions"
import { ArrowLeft, ArrowRight, Group, MeetingRoom, Nightlight, PointOfSale, Send } from "@mui/icons-material"
import IconButton from "@mui/material/IconButton"


export interface Room {
  hotelName?: string;
  roomName: string;
  roomNumber: number;
  roomType: string;
  capacity: number;
  description: string;
  price: number;
  priceList: number[];
  total: number;
  priceUnit: string;
  imageSource: string;
  imageSourceList: string[];
}

export interface RequestedRoom extends Room {
  bookedCapacity: number;
  bookedPrice: number;
}

export interface InputProps<T> {
  value: T;
  onChange: (value: T) => void;
}

export interface ContainerProps<T> extends InputProps<T> {
  completed: () => void;
}

export interface OrderRequest {
  selectedRooms: RequestedRoom[];
  orderInfo: ContactForm;
  dateFrom: Date;
  dateTo: Date;
  durationsInDays: number;
  totalPrice: number;
  totalPriceWithVAT: number;
}

export function BookingScreen() {



  const [orderSent, setOrderSent] = useState<boolean>(false)
  const { t, i18n } = useTranslation()
  const steps: string[] = [t("reservation"), t("check_in_out"), t("contact_form"), t("summary")]
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const [rooms, setRooms] = useState<Room[]>(jsonData as Room[] ?? [])
  const [checkInOut, setCheckInOut] = useState<CheckInOut>({})
  const [selectedRooms, setSelectedRooms] = useState<Map<Room, number>>(new Map<Room, number>())
  const [form, setForm] = useState<ContactForm>(
    {
      name: "",
      email: "",
      tel: "",
    },
  )
  const [requestedRooms, setRequestedRooms] = useState<RequestedRoom[]>()

  const selectRooms = (roomsMap: Map<Room, number>): RequestedRoom[] => {
    let selectedRooms: RequestedRoom[] = []
    roomsMap.forEach((quantity, room) => {
      const selectedRoom: RequestedRoom = { bookedCapacity: 0, bookedPrice: 0, ...room }
      selectedRoom.bookedCapacity = quantity
      selectedRoom.bookedPrice = selectedRoom.priceList[quantity-1]
      if (quantity > 0) selectedRooms = selectedRooms.concat(selectedRoom)
    })
    return selectedRooms
  }

  const countBookedRooms = useMemo(() => {
    let count = 0
    selectedRooms.forEach((quantity, room) => {
      if (quantity > 0) count++
    })
    return count
  },[selectedRooms])

  const totalPricePerDay = (rooms: RequestedRoom[]): number => {
    let totalPrice = 0
    rooms.forEach(( room) => {
      totalPrice = totalPrice + room.bookedPrice
    })
    return totalPrice
  }

  async function sendOrder() {
    const tmp = selectRooms(selectedRooms)
    const orderRequest: OrderRequest = {
      selectedRooms: tmp,
      orderInfo: form,
      dateFrom: checkInOut.checkIn ?? new Date(),
      dateTo: checkInOut.checkOut ?? new Date(new Date().setDate(new Date().getDate() + 1)),
      durationsInDays: durationInDays(checkInOut.checkIn, checkInOut.checkOut),
      totalPrice: durationInDays(checkInOut.checkIn, checkInOut.checkOut) * totalPricePerDay(tmp) ?? 0,
      totalPriceWithVAT: Math.round(durationInDays(checkInOut.checkIn, checkInOut.checkOut) * totalPricePerDay(tmp) * 1.12) ?? 0,
    }
    const pureObj = JSON.parse(JSON.stringify(orderRequest))
    const docRef = await addDoc(collection(firestore, "Order"), pureObj)
    console.debug("Document written with ID: ", docRef.id)
    setOrderSent(true)
  }

  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState<Record<number, boolean>>({ 0: false, 1: false, 2: false })

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ?
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }

  const handleComplete = () => {
    const newCompleted: Record<number, boolean> = { ...completed }
    newCompleted[activeStep] = true
    console.debug("newCompleted", newCompleted)
    setCompleted(newCompleted)
  }

  const pluralRule = (key: "rooms" | "people" | "days", number: number) => {
    const pluralRules = new Intl.PluralRules(i18n.language)
    const pluralKey = pluralRules.select(number)
    return `${key}_count_${pluralKey}`
  }

  const sumMapCount = () => {
    let sum = 0
    selectedRooms.forEach((value) => {
      sum += value
    })
    return sum
  }


  return (<Screen>

    <View
      style={{ padding: 40, paddingTop: 100, width: "100%", justifyContent: "center", flexDirection: "row" }}>
    </View>
    <View
      style={{
        width: "100vw",
        height: 50,
        position: "fixed",
        top: 70,
        justifyContent: "space-evenly",
        backgroundColor: "#fff",
        zIndex: 1,
        alignItems: "center",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
      }}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>{width < 850 &&
        <MeetingRoom style={{ marginRight: 5 }} />}<h5
        style={{ margin: 0 }}>{`${width > 550 ? t(pluralRule("rooms", countBookedRooms), { value: countBookedRooms }): String(countBookedRooms)}`}</h5>
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>{width < 850 && <Group style={{ marginRight: 5 }} />}
        <h5
          style={{ margin: 0 }}>{ `${width > 550 ? t(pluralRule("people", sumMapCount()), { value: sumMapCount() }) : sumMapCount()}`}</h5>
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>{width < 850 &&
        <Nightlight style={{ marginRight: 5 }} />}<h5
        style={{ margin: 0 }}>{`${width > 550 ? t(pluralRule("days", durationInDays(checkInOut.checkIn, checkInOut.checkOut)), { value: durationInDays(checkInOut.checkIn, checkInOut.checkOut) }) : durationInDays(checkInOut.checkIn, checkInOut.checkOut)}`}</h5>
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>{width < 850 &&
        <PointOfSale style={{ marginRight: 5 }} />}
        <h5 style={{ margin: 0 }}>{`${t("total_price_per_night", {
          value: durationInDays(checkInOut.checkIn, checkInOut.checkOut) * totalPricePerDay(selectRooms(selectedRooms)),
          priceUnit: "CZK bez DPH",
        })}`}</h5></View>
    </View>
    <View
      style={{ width: width < 1000 ? "100%" : "70%", flexDirection: "column", alignItems: "center", padding: 15 }}><View
      style={{ padding: 15, marginBottom: 40, flexDirection: "column", alignItems: "center", width: "100%" }}>
      {activeStep === steps.length - 1 ? (
          <ConfirmationContainer
            completed={() => handleComplete()}
            orderSent={orderSent}
            value={{ selectedRooms: selectRooms(selectedRooms), form: form, checkInOut: checkInOut }}
            onChange={() => sendOrder()}
          />) :
        activeStep === 0 ? (
            <ReservationContainer
              completed={() => handleComplete()}
              data={rooms}
              value={selectedRooms}
              onChange={setSelectedRooms}
            />) :
          activeStep === 1 ? (
              <CheckInOutContainer
                completed={() => handleComplete()}
                value={checkInOut}
                onChange={setCheckInOut}
              />) :
            (<ContactFormContainer
              completed={() => handleComplete()}
              value={form}
              onChange={setForm}
            />)}</View>
      <View
        style={{
          width: "100vw",
          position: "fixed",
          justifyContent: "space-around",
          bottom: 0,
          backgroundColor: "#fff",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
        }}><View
        style={{
          padding: 20,
          width: width < 1250 ? "100%" : "70%",
          justifyContent: "space-between",
          alignItems: "center",
        }}>{width > 850 ?
        <Button
          variant="contained"
          onClick={() => activeStep !== 0 ? handleBack() : navigate("/")}>{t("previous")}</Button> :
        <IconButton onClick={() => activeStep !== 0 ? handleBack() : navigate("/")}>
          <ArrowLeft fontSize={"medium"} style={{ color: "#1976d2" }} />
        </IconButton>}
        {width > 750 ? <Stepper activeStep={activeStep} style={{ width: "100%", paddingLeft: 20, paddingRight: 20 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper> : <Step completed={completed[activeStep]}>
          <StepButton color="inherit" onClick={handleStep(activeStep)}>
            {steps[activeStep]}
          </StepButton>
        </Step>}
        {!isLastStep() ?
          <>{width > 850 ? <Button disabled={!(completed[activeStep])} variant="contained"
                                   onClick={handleNext}>{t("next")}</Button> :
            <IconButton disabled={!(completed[activeStep])}
                        onClick={handleNext}>
              <ArrowRight fontSize={"medium"} style={{ color: "#1976d2" }} />
            </IconButton>}</> :
          <>{width > 850 ? <Button
            disabled={orderSent || allStepsCompleted()}
            variant="contained"
            onClick={() => sendOrder()}>
            {t("order.message.send_button")}
          </Button> : <IconButton disabled={orderSent || allStepsCompleted()}
                                  onClick={() => sendOrder()}>
            <Send fontSize={"small"} style={{ color: "#1976d2" }} />
          </IconButton>}</>
        }</View>
      </View>
    </View>
  </Screen>)
}
