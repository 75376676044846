import { useTranslation } from "react-i18next"
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "../../hooks/WindowDimensions"
import { Room } from "../../screens/BookingScreen"
import { View } from "../general/View"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import { Pressable } from "../general/Pressable"
import { ArrowLeft, ArrowRight } from "@mui/icons-material"
import { BlurredUpImage } from "../general/BlurredUpImage"

export interface RoomItemProps {
  style?: CSSProperties
  onPress?: () => void
  value?: Room
  buttonText?: ReactNode
}

export interface RoomItemDimensions {
  width: number
  height: number
}

export function RoomItem(props: RoomItemProps) {
  const { t, i18n } = useTranslation()
  const { width, height } = useWindowDimensions()
  const [dimensions, setDimensions] = useState<RoomItemDimensions>({ width: 0, height: 0 })
  const ref = useRef<Carousel>(null)
  const [images, setImages] = useState<string[]>(props.value?.imageSourceList ?? [])

  useEffect(() => {
    if (ref.current) {
      const { width, height } = ref.current.getInitialImage() || {}
      if (width && height) {
        setDimensions({ width, height })
      }
    }
  }, [width, height])

  useEffect(() => {
    setImages([...props.value?.imageSourceList ?? []])
  }, [props.value?.imageSourceList])

  const arrowStyles: CSSProperties = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "#fff",
  }

  const pluralRule = (number: number) => {
    const pluralRules = new Intl.PluralRules(i18n.language)
    const pluralKey = pluralRules.select(number)
    return `people_count_${pluralKey}`
  }

  return <View style={{ paddingBottom: 10 }}>
    <Carousel
      ref={ref}
      autoPlay
      infiniteLoop
      stopOnHover
      animationHandler={"slide"}
      interval={7000}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      width={"100%"}
      renderArrowNext={(clickHandler, hasNext) => hasNext &&
        <ArrowRight style={{ ...arrowStyles, right: 15 }} onClick={clickHandler} />}
      renderArrowPrev={(clickHandler, hasPrev) => hasPrev &&
        <ArrowLeft style={{ ...arrowStyles, left: 15 }} onClick={clickHandler} />}
    >
      {images.map((value, index) =>
        <div key={index + value}>
          <div
            style={{
              width: dimensions.width,
              position: "absolute",
              top: 0,
              backgroundColor: "rgba(114, 114, 114, 0.5)",
            }}>
            <h2 style={{ color: "#fff", paddingLeft: 10 }}>{`#${props.value?.roomNumber} ${props.value?.roomName}`}</h2>
          </div>
          <img
            key={value}
            alt={value}
            src={value}
            style={{
              width: "100%",
              aspectRatio: "1/1",
              objectFit: "cover",
              height: "100vh",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: dimensions.width,
              boxSizing: "border-box",
              backgroundColor: "rgba(114, 114, 114, 0.5)",
            }}>
            <div style={{ display: "flex", justifyContent: "flex-end", boxSizing: "border-box" }}>
              <View
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "right", paddingRight: 5,
                }}>
                <p style={{ color: "#fff" }}>
                  {t(pluralRule(props.value?.capacity ?? 0), { value: props.value?.capacity ?? 0 })}
                  <span className={"icon icon-person"} style={{ paddingLeft: 5 }} />
                </p>
                <p style={{ color: "#fff" }}>
                  {props.value?.roomName ?? ""}
                  <span className={"icon icon-bed"} style={{ paddingLeft: 5 }} />
                </p>
                <p style={{ color: "#fff" }}>
                  {props.value?.description ?? ""}
                  <span className={"icon icon-plus"} style={{ paddingLeft: 5 }} />
                </p>
              </View>
            </div>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Pressable
                style={{ width: "50%", justifyContent: "center", alignItems: "center", backgroundColor: "#ff0000" }}
                onPress={props.onPress}>
                <h3 style={{ color: "#fff" }}>{props.buttonText ?? t("book")}</h3>
              </Pressable>
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <h3 style={{ color: "#fff", margin: 0 }}>{t("from", { value: props.value?.price ?? 0 })}</h3>
              </div>
            </div>
          </div>
        </div>)}
    </Carousel>
  </View>
}
