import { ContainerProps, RequestedRoom, Room } from "../../screens/BookingScreen"
import { RoomTable } from "./RoomTable"
import { View } from "../general/View"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { CheckInOut } from "./CheckInOutContainer"
import { ContactForm } from "./ContactFormContainer"
import { durationInDays } from "../../utils/date"
import { Button } from "@mui/material"

export interface ConfirmationContainerProps extends ContainerProps<{
  selectedRooms: RequestedRoom[],
  form: ContactForm,
  checkInOut: CheckInOut
}> {
  allStepsCompleted?: boolean
  orderSent?: boolean
}

export function ConfirmationContainer({ value, orderSent }: ConfirmationContainerProps) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const buttonDisabled = () => {
    return value.selectedRooms.length <= 0 && durationInDays(value.checkInOut.checkIn, value.checkInOut.checkOut) <= 0
  }


  return (<View style={{ flexDirection: "column", alignItems: "center" }}>
    {!orderSent ? <>
        <h3>{`${value.checkInOut?.checkIn?.toLocaleDateString(i18n.language)} - ${value.checkInOut.checkOut?.toLocaleDateString(i18n.language)}`}</h3>
        <RoomTable bookedRooms={value.selectedRooms}
                   durationInDays={durationInDays(value.checkInOut.checkIn, value.checkInOut.checkOut)} /></> :
      <><h3>{t("order.message.title")}</h3><h4>{t("order.message.body", { email: value.form.email })}</h4><Button
        disabled={buttonDisabled()} onClick={() => navigate("/")}>
        {t("order.message.button")}
      </Button></>
    }
  </View>)
}
