import React, { useEffect, useState } from "react"
import { TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ContainerProps } from "../../screens/BookingScreen"
import { View } from "../general/View"
import { useWindowDimensions } from "../../hooks/WindowDimensions"

export interface ContactForm {
  name: string;
  email: string;
  tel: string;
}

export function ContactFormContainer({ value, onChange, completed }: ContainerProps<ContactForm>) {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const [blurredFields, setBlurredFields] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    if (value.email) {
      isValidEmail(value.email) && completed()
    }
  }, [value])

  const handleBlur = (key: string) => {
    setBlurredFields({ ...blurredFields, [key]: true })
  }

  const handleChange = (key: string, inputValue: string) => {
    setBlurredFields({ ...blurredFields, [key]: true })
    onChange({ ...value, [key]: inputValue })
  }

  // Regular expressions for email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  // Validation function for email
  const isValidEmail = (email: string) => emailRegex.test(email)

  return (
    <View style={{ width: width > 850 ? "50%": "99%", flexDirection: "column" }}>
      {Object.keys(value).map((key) => (
        <div key={key} style={{ paddingBottom: 5 }}>
          <TextField
            fullWidth
            autoComplete={key}
            error={blurredFields[key] && key === "email" && !isValidEmail(value.email)}
            id="outlined-basic"
            label={t(key)}
            value={value[key as keyof ContactForm]}
            variant="outlined"
            helperText={
              blurredFields[key] &&
              key === "email" &&
              !isValidEmail(value.email) &&
              t("validation.email.error.message")
            }
            onBlur={() => handleBlur(key)}
            onChange={(event) => handleChange(key, event.target.value)}
          />
        </div>
      ))}
    </View>
  )
}
