import { ContainerProps } from "../../screens/BookingScreen"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import moment from "moment/moment"
import { useTranslation } from "react-i18next"
import { View } from "../general/View"
import { useEffect } from "react"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { useWindowDimensions } from "../../hooks/WindowDimensions"

export interface CheckInOut {
  checkIn?: Date;
  checkOut?: Date;
}

export function CheckInOutContainer({ value, onChange, completed }: ContainerProps<CheckInOut>) {
  const { t, i18n } = useTranslation()
  const {width} = useWindowDimensions()

  useEffect(() => {
    if (value.checkIn && value.checkOut) {
      completed && completed()
    }
  }, [value.checkIn, value.checkOut])


  return <View style={{ width: width > 850 ? "50%": "99%", flexDirection: "column" }}>
    <LocalizationProvider adapterLocale={i18n.language}
                          dateAdapter={AdapterMoment}>
      <View style={{ paddingBottom: 5 }}>
        <DatePicker
          disablePast
          label={t("check_in")}
          sx={{ width: "100%" }}
          value={moment(value.checkIn)}
          onChange={(date) => onChange({ ...value, checkIn: date?.toDate() })}
        />
      </View>
      <View style={{ paddingBottom: 5 }}>
        <DatePicker
          disabled={!value.checkIn}
          label={t("check_out")}
          maxDate={moment().add(1, "year")}
          minDate={moment(value.checkIn).add(1, "day")}
          sx={{ width: "100%" }}
          value={moment(value.checkOut)}
          onChange={(date) => onChange({ ...value, checkOut: date?.toDate() })}
        />
      </View></LocalizationProvider>
  </View>
}
