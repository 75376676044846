import { initializeApp } from "firebase/app"
import { getFirestore } from "@firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyDSnFxLhJ46ocXktF_19hj85GcQeSjNI8Y",
  authDomain: "hotel-herman.firebaseapp.com",
  projectId: "hotel-herman",
  storageBucket: "hotel-herman.appspot.com",
  messagingSenderId: "88423299227",
  appId: "1:88423299227:web:8c7bab8740063d3be12e3e",
  measurementId: "G-0KWEW4B25L",
}

const app = initializeApp(firebaseConfig)

export const firestore = getFirestore(app)
